import ClickOutside from 'vue-click-outside';
import debounce from 'lodash/debounce';

export default {
  name: '',
  data() {
    return this.initial_state();
  },
  computed: {
    checkWidth() {
      if (screen.width >= 992) {
        return 'height:80px;';
      }
      return 'height:70px;';
    },
    // checkBackground() {
    //   return this.backgroundColor;
    // },
  },
  directives: {
    ClickOutside,
  },
  components: {
    logo: () => import('../reusables/logo.vue'),
  },
  methods: {
    initial_state() {
      return {
        backgroundColor: 'banner',
      };
    },
    hide() {
      if ($('.navbar-toggler').attr('aria-expanded') === 'true') {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
      }
    },
  },
  mounted() {
    // $(window).scroll(
    //   debounce(() => {
    //     if ($(window).scrollTop() > 20) {
    //       this.backgroundColor = 'bg-white shadow';
    //     } else {
    //       this.backgroundColor = 'banner';
    //     }
    //   }, 500),
    // );
  },
  beforeDestroy() {
    // $(window).off('scroll');
  },
};
